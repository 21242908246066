import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Modal,
  Box
} from '@mui/material';

import {
  MusicNote,
  MusicOff,
  Brightness7,
  Brightness4,
  NotificationsActive,
  NotificationsOff,
  VolumeUp,
  VolumeOff,
  EnergySavingsLeaf,
  EnergySavingsLeafOutlined,
  VolumeMute,
  VolumeDown,
  Add,
  Remove
} from '@mui/icons-material';

import {
  setDarkMode,
  setNotificationsAllowed,
  toggleMute,
  toggleMusic,
  toggleLowGraphics,
  toggleGameSounds,
  toggleNotificationSounds,
  toggleClickounds
} from '../../redux/Auth/user.actions';

// ------------------ MUI Modal Styles ------------------
const modalBoxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#400068', // Purple background
  color: '#fff',
  boxShadow: 24,
  borderRadius: 1,
  p: 3,
  width: 500,
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto'
};

const SettingsModal = ({
  modalIsOpen,
  closeModal,
  isMuted,
  isLowGraphics,
  isDarkMode,
  isNotificationsAllowed,
  toggleLowGraphics,
  setDarkMode,
  toggleMute,
  toggleMusic,
  setNotificationsAllowed,
  isMusicEnabled,
  clickSounds,
  gameSounds,
  notificationSounds,
  toggleClickounds,
  toggleGameSounds,
  toggleNotificationSounds
}) => {
  const [showSubSettings, setShowSubSettings] = useState(false);

  const handleMuteToggle = () => {
    toggleMute(!isMuted);
    toggleClickounds(!isMuted);
    toggleGameSounds(!isMuted);
    toggleNotificationSounds(!isMuted);
  };

  const toggleSubSettingsVisibility = () => {
    setShowSubSettings((prev) => !prev);
  };

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      sx={{ zIndex: 1300 }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } }}
    >
      <Box sx={modalBoxStyle}>
        {/* HEADER */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 2
          }}
        >
          <Box component="h2" sx={{ m: 0, fontWeight: 'bold' }}>
            Settings
          </Box>
          <Button variant="text" color="error" onClick={closeModal}>
            X
          </Button>
        </Box>

        {/* BODY */}
        <Table>
          <TableBody>
            {/* Mute / Unmute */}
            <TableRow>
              <TableCell>
                {isMuted ? (
                  <Button onClick={handleMuteToggle} sx={{ textTransform: 'none' }}>
                    <VolumeOff /> &nbsp; UNMUTE
                  </Button>
                ) : (
                  <Button onClick={handleMuteToggle} sx={{ textTransform: 'none' }}>
                    <VolumeUp /> &nbsp; MUTE
                  </Button>
                )}
              </TableCell>
              <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <IconButton onClick={toggleSubSettingsVisibility}>
                  {showSubSettings ? <Remove /> : <Add />}
                </IconButton>
                <Checkbox checked={isMuted} onChange={handleMuteToggle} />
              </TableCell>
            </TableRow>

            {/* Sub-settings for sounds */}
            {showSubSettings && (
              <>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => toggleClickounds(!clickSounds)}
                      sx={{ textTransform: 'none' }}
                    >
                      {clickSounds ? <VolumeMute /> : <VolumeDown />} &nbsp; Typing &amp; Clicks
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={clickSounds}
                      onChange={() => toggleClickounds(!clickSounds)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => toggleGameSounds(!gameSounds)}
                      sx={{ textTransform: 'none' }}
                    >
                      {gameSounds ? <VolumeMute /> : <VolumeDown />} &nbsp; Game Sounds
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={gameSounds}
                      onChange={() => toggleGameSounds(!gameSounds)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Button
                      onClick={() => toggleNotificationSounds(!notificationSounds)}
                      sx={{ textTransform: 'none' }}
                    >
                      {notificationSounds ? <VolumeMute /> : <VolumeDown />}&nbsp; Notifications
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={notificationSounds}
                      onChange={() => toggleNotificationSounds(!notificationSounds)}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}

            {/* Graphics */}
            <TableRow>
              <TableCell>
                {isLowGraphics ? (
                  <Button
                    onClick={() => toggleLowGraphics(!isLowGraphics)}
                    sx={{ textTransform: 'none' }}
                  >
                    <EnergySavingsLeaf /> &nbsp; LOW GRAPHICS
                  </Button>
                ) : (
                  <Button
                    onClick={() => toggleLowGraphics(!isLowGraphics)}
                    sx={{ textTransform: 'none' }}
                  >
                    <EnergySavingsLeafOutlined /> &nbsp; HIGH GRAPHICS
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={isLowGraphics}
                  onChange={() => toggleLowGraphics(!isLowGraphics)}
                />
              </TableCell>
            </TableRow>

            {/* <TableRow>
              <TableCell>
                {isDarkMode ? (
                  <Button onClick={() => setDarkMode(!isDarkMode)} sx={{ textTransform: 'none' }}>
                    <Brightness4 /> &nbsp; DARK MODE
                  </Button>
                ) : (
                  <Button onClick={() => setDarkMode(!isDarkMode)} sx={{ textTransform: 'none' }}>
                    <Brightness7 /> &nbsp; LIGHT MODE
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <Checkbox checked={isDarkMode} onChange={() => setDarkMode(!isDarkMode)} />
              </TableCell>
            </TableRow> */}

            {/* Notifications */}
            <TableRow>
              <TableCell>
                {isNotificationsAllowed ? (
                  <Button
                    onClick={() => setNotificationsAllowed(!isNotificationsAllowed)}
                    sx={{ textTransform: 'none' }}
                  >
                    <NotificationsActive /> &nbsp; NOTIFICATIONS ON
                  </Button>
                ) : (
                  <Button
                    onClick={() => setNotificationsAllowed(!isNotificationsAllowed)}
                    sx={{ textTransform: 'none' }}
                  >
                    <NotificationsOff /> &nbsp; NOTIFICATIONS OFF
                  </Button>
                )}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={isNotificationsAllowed}
                  onChange={() => setNotificationsAllowed(!isNotificationsAllowed)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
};

// ------------------ REDUX CONNECT ------------------
const mapStateToProps = (state) => ({
  isDarkMode: state.auth.isDarkMode,
  isNotificationsAllowed: state.auth.isNotificationsAllowed,
  isMuted: state.auth.isMuted,
  clickSounds: state.auth.clickSounds,
  gameSounds: state.auth.gameSounds,
  notificationSounds: state.auth.notificationSounds,
  isMusicEnabled: state.auth.isMusicEnabled,
  isLowGraphics: state.auth.isLowGraphics
});

const mapDispatchToProps = {
  toggleMute,
  toggleClickounds,
  toggleNotificationSounds,
  toggleGameSounds,
  toggleMusic,
  toggleLowGraphics,
  setDarkMode,
  setNotificationsAllowed
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);